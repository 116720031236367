import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Book, Reorder} from "@material-ui/icons";

// core components
import Button from "Atoms/CustomButtons/Button.js";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
          <Button
              href="https://bjaffke.medium.com/"
              target="_blank"
              color="transparent"
              variant="outlined"
              className={classes.navLink}
          >
              <Reorder className={classes.icons} /> Blog
          </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Link to="/Resume">
              <Button
              color="transparent"
              variant="outlined"
              className={classes.navLink}
              >
              <Book className={classes.icons} /> Resume
            </Button>
          </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow me on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/BradJaffke"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="My Github Repo"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://github.com/BradJaffke/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-github"} />
          </Button>
        </Tooltip>
      </ListItem>
        <ListItem className={classes.listItem}>
            <Tooltip
                id="linkedin-tooltip"
                title="Connect with me on LinkedIn"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    color="transparent"
                    href="https://www.linkedin.com/in/bradjaffke/"
                    target="_blank"
                    className={classes.navLink}
                >
                    <i className={classes.socialIcons + " fab fa-linkedin"} />
                </Button>
            </Tooltip>
        </ListItem>
    </List>
  );
}
