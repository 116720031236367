import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'

import "assets/scss/material-kit-react.scss?v=1.9.0";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
